import React from 'react';
import { inject, observer } from 'mobx-react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

@inject('store')
@observer
class AutopayForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            autopayStartDateRange: '',
        };
        this.handleAutopayStartDate = this.handleAutopayStartDate.bind(this);
        this.handleAutopayEndDate = this.handleAutopayEndDate.bind(this);
        this.handleAutopayEndDateAction = this.handleAutopayEndDateAction.bind(this);
        this.handleAutopayFrequency = this.handleAutopayFrequency.bind(this);
        this.endDateAutopayCalendar = React.createRef();
        this.getOptionsFrequency = this.getOptionsFrequency.bind(this);
        this.handleAutopayCalendarEndDate = this.handleAutopayCalendarEndDate.bind(this);
        this.getCountDaysByFrequency = this.getCountDaysByFrequency.bind(this);
        this.renderFrequencyOptions = this.renderFrequencyOptions.bind(this);
        this.renderCustomHeaderContent = this.renderCustomHeaderContent.bind(this);
        this.validateDate = this.validateDate.bind(this);
    }

    componentDidMount() {
        this.setState({autopaStartDateRange: this.props.store.paymentPage.autopay.startDateRange})
    }

    handleAutopayStartDate(date){
        this.props.store.handleAutopayStartDate(date)
        this.handleAutopayEndDate(null)
    }

    handleAutopayEndDate(date){
        this.props.store.handleAutopayEndDate(date);
    }

    handleAutopayEndDateAction(value){
        this.props.store.handleAutopayEndDateAction(value);
        this.endDateAutopayCalendar.current.setOpen(false)
    }

    handleAutopayFrequency(e){
        this.props.store.handleAutopayFrequency(e)
        this.handleAutopayEndDate(null)
    }

    handleAutopayCalendarEndDate(date){
        this.props.store.handleAutopayCalendarEndDate(date)
    }

    getOptionsFrequency(){
        return [
            { key: 'onetime', value: 'onetime', label: 'Onetime' },
            { key: 'weekly', value: 'weekly', label: 'Weekly', days: 7 },
            { key: 'every2Weeks', value: 'every2weeks', label: 'Every 2 weeks', days: 14 },
            { key: 'monthly', value: 'monthly', label: 'Monthly', days: 30 },
            { key: 'every3Months', value: 'every3months', label: 'Every 3 months', days: 90 },
            { key: 'every6Months', value: 'every6months', label: 'Every 6 months', days: 180 },
            { key: 'annually', value: 'annually', label: 'Annually', qaid: 'annuallyAutopayOption', days: 365 },
        ];
    }

    getCountDaysByFrequency(){
        if(this.props.store.paymentPage.autopay.frequencySelected){
            const arrFrequency = this.getOptionsFrequency().filter(option => option.value === this.props.store.paymentPage.autopay.frequencySelected);
            if (arrFrequency?.length > 0) {
                return arrFrequency[0].days || 1;
            }
        }
        return 1;
    }

    renderFrequencyOptions() {
        const frequencyData = this.props.store.paymentPage.autopay.frequency;
        const options = this.getOptionsFrequency();    
        return options.map(option => {
            const { key, value, label, qaid } = option;
            return frequencyData[key] && (
                <option key={value} value={value} data-qaid={qaid}>
                    {label}
                </option>
            );
        });
    }

    renderCustomHeaderContent( {date, changeYear, changeMonth, decreaseMonth, increaseMonth, prevMonthButtonDisabled, nextMonthButtonDisabled}, endDate = false ) {
        let years = [];
        let yearsEndDate = [];
        let months = this.props.store.getMonths();
        let year = new Date().getFullYear();

        for (let i = year; i <= year + 2; i++) {
            years.push(i);
        }
        for (let i = year; i <= year + 20; i++) {
            yearsEndDate.push(i);
        }
        if(endDate){
            years = yearsEndDate;
        }

        return (
            <div
                style={{
                    margin: 10,
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                <button
                    onClick={decreaseMonth}
                    disabled={prevMonthButtonDisabled}
                >
                    {'<'}
                </button>
                <select
                    value={date.getFullYear()}
                    onChange={({ target: { value } }) =>
                        changeYear(value)
                    }
                >
                    {years.map((option) => (
                        <option key={option} value={option}>
                            {option}
                        </option>
                    ))}
                </select>
    
                <select
                    value={months[date.getMonth()]}
                    onChange={({ target: { value } }) =>
                        changeMonth(months.indexOf(value))
                    }
                >
                    {months.map((option) => (
                        <option key={option} value={option}>
                            {option}
                        </option>
                    ))}
                </select>
    
                <button
                    onClick={increaseMonth}
                    disabled={nextMonthButtonDisabled}
                >
                    {'>'}
                </button>
            </div>
        );
    }

    validateDate = (date) => {
        const startDate = new Date(date), today = new Date();	
		if (!isNaN(startDate.getFullYear()) && startDate.getFullYear() >= today.getFullYear()) {
			return true
		}
		return false
	};
    
    render() {
        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);

        let nextTwoDays = new Date();
        nextTwoDays.setDate(nextTwoDays.getDate() + 2);
        if(this.props.store.paymentPage.autopay.startDate){
            const days = parseInt(this.getCountDaysByFrequency());
            nextTwoDays = new Date(this.props.store.paymentPage.autopay.startDate);
            nextTwoDays.setDate(nextTwoDays.getDate() + days);
        }

        const filterDates = (date) => {
            const allowedDaysString = this.state.autopayStartDateRange;
            if (!allowedDaysString || allowedDaysString === "") {
                return true;
            }        
            const allowedDays = allowedDaysString.split(",").map((range) => {
                const [start, end] = range.split("-");
                return { start: parseInt(start), end: parseInt(end) };
            });        
            return allowedDays.some((range) => {
                const day = date.getDate();
                return day >= range.start && day <= range.end;
            });
        };        

        return (
            <li className="ui-state-default dragable-panel" id="blockautopay">
                <h6 className="sub-header mb-3">
                    {this.props.store.paymentPage.autopay.header
                        ? this.props.store.paymentPage.autopay.header
                        : ''}
                </h6>
                <div className="row">
                    <div className="col-sm mb-3">
                        <DatePicker
                            customInput={
                                <div className="form-floating">
                                    <input
                                        style={{ backgroundColor: '#fff' }}
                                        readOnly
                                        autoComplete="off"
                                        onChange={function () {}}
                                        value={this.validateDate(this.props.store.paymentPage.autopay.startDate) ? this.props.store.dateStringFormat(this.props.store.paymentPage.autopay.startDate) : ''}
                                        name="startDateAutopayCalendar"
                                        id="startDateAutopayCalendar"
                                        className={
                                            this.props.store.getPaymentPageErrors
                                                .autopayStartDateError
                                                ? 'form-control input-calendar input-error input-lg'
                                                : 'form-control input-calendar input-lg'
                                        }
                                        placeholder="Start Date"
                                    />
                                    <label htmlFor="startDateAutopayCalendar">
                                        Start Date
                                    </label>
                                </div>
                            }
                            selected={this.validateDate(this.props.store.paymentPage.autopay.startDate) ? (this.props.store.paymentPage.autopay.startDate instanceof Date ? this.props.store.paymentPage.autopay.startDate : new Date(this.props.store.paymentPage.autopay.startDate) ): ''}
                            filterDate={filterDates}
                            onChange={(date) => this.handleAutopayStartDate(date)}
                            minDate={tomorrow}
                            dayClassName={(date) => 'calendar-day'}
                            popperPlacement="bottom-start"
                            renderCustomHeader={(props) => this.renderCustomHeaderContent(props)}
                        />
                    </div>
                    <div className="col-sm">
                        <div className="form-floating mb-3">
                            <select
                                value={this.props.store.paymentPage.autopay.frequencySelected || ''}
                                onChange={(e) => this.handleAutopayFrequency(e)}
                                className={this.props.store.getPaymentPageErrors.autopayFrequencyError ? "form-select form-control input-error" : "form-select form-control"}
                                id="floatingSelectGrid"
                                aria-label="Frequency"
                                data-qaid="frequencyAutopayMoneyIn"
                            >
                                <option defaultValue="select" value="">Select...</option>
                                {this.renderFrequencyOptions()}
                            </select>
                            <label htmlFor="floatingSelectGrid">Frequency</label>
                        </div>
                    </div>
                    {this.props.store.paymentPage.autopay.frequencySelected !==
                        'onetime' && (
                        <div className="col-sm">
                            {this.props.store.paymentPage.autopay.finish
                                .untilCancelled && (
                                <div className="form-floating mb-3">
                                    <select
                                        value={
                                            this.props.store.paymentPage.autopay
                                                .finishSelected
                                                ? this.props.store.paymentPage.autopay
                                                        .finishSelected
                                                : ''
                                        }
                                        onChange={(e) => this.handleAutopayEndDate(e)}
                                        className={
                                            this.props.store.getPaymentPageErrors
                                                .autopayFinishError
                                                ? 'form-select form-control input-error'
                                                : 'form-select form-control'
                                        }
                                        id="floatingSelectGrid"
                                        aria-label="End Date"
                                    >
                                        <option value=""> Select... </option>
                                        <option value="untilcancelled">
                                            Until Cancelled
                                        </option>
                                    </select>
                                    <label htmlFor="floatingSelectGrid">End Date</label>
                                </div>
                            )}

                            {this.props.store.paymentPage.autopay.finish.calendar && (
                                <>
                                    <DatePicker
                                        customInput={
                                            <div className="form-floating">
                                                <input
                                                    style={{ backgroundColor: '#fff' }}
                                                    readOnly
                                                    autoComplete="off"
                                                    onChange={function () {}}
                                                    value={
                                                        this.props.store.paymentPage.autopay
                                                            .finishSelected &&
                                                        this.props.store.paymentPage.autopay
                                                            .finishSelected instanceof Date
                                                            ? this.props.store.dateStringFormat(
                                                                    this.props.store.paymentPage.autopay
                                                                        .finishSelected
                                                                )
                                                            : this.props.store.paymentPage.autopay
                                                                    .finishSelected === 'untilcancelled'
                                                            ? 'Until Cancelled'
                                                            : ''
                                                    }
                                                    name="endDateAutopayCalendar"
                                                    id="endDateAutopayCalendar"
                                                    className={
                                                        this.props.store.getPaymentPageErrors
                                                            .autopayFinishError
                                                            ? 'form-control input-calendar input-error input-lg'
                                                            : 'form-control input-calendar input-lg'
                                                    }
                                                    placeholder="End Date"
                                                />
                                                <label htmlFor="endDateAutopayCalendar">
                                                    End Date
                                                </label>
                                            </div>
                                        }
                                        selected={
                                            this.props.store.paymentPage.autopay
                                                .finishSelected &&
                                            this.props.store.paymentPage.autopay
                                                .finishSelected instanceof Date
                                                ? this.props.store.paymentPage.autopay
                                                        .finishSelected
                                                : ''
                                        }
                                        onChange={(date) =>
                                            this.handleAutopayCalendarEndDate(date)
                                        }
                                        minDate={nextTwoDays}
                                        ref={this.endDateAutopayCalendar}
                                        dayClassName={(date) => 'calendar-day'}
                                        popperPlacement="bottom-end"
                                        renderCustomHeader={(props) => this.renderCustomHeaderContent(props, true)}
                                    >
                                        <div
                                            className="linkCalendar"
                                            onClick={(e) =>
                                                this.handleAutopayEndDateAction(
                                                    'untilcancelled'
                                                )
                                            }
                                        >
                                            Until Cancelled
                                        </div>
                                    </DatePicker>
                                </>
                            )}
                        </div>
                    )}
                </div>
            </li>
        );
    }
}

export { AutopayForm };