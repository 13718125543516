import React from 'react';
import { inject, observer } from 'mobx-react';
import { BiError } from 'react-icons/bi';

@inject('store')
@observer
class PaylinkDeleted extends React.Component {

    prevent(e) {
        e.preventDefault();
    }    

    componentDidMount() {
       
    }

    render() {
        const bgTheme = {
            backgroundColor: this.props.store.paymentPageSettings.color,
        };

        return (
            <div>
            <div className="bg-decorator" style={bgTheme}>&nbsp;</div>
            <div className="mt-body3">
            <div className="d-flex flex-column sjustify-content-center align-items-center">
                <div className="card" style={{width: '50em'}}>
                    <div className="card-body text-center" style={{padding: "40px"}}>
                        <BiError className="mb-4" style={{fontSize: "60px", color: '#FBBF24' }}/>
                        <h5>Payment link Deleted</h5>
                        <p className="small">
                            The invoice associated with this payment link has been deleted,<br/> and the link is no longer available.
                        </p>
                    </div>
                </div>
            </div>
            </div>
            </div>
        );
    }
}

export { PaylinkDeleted };