import React from 'react';
import { MessagePage } from "../components/MessagePage";

class NotFound extends React.Component {

    prevent(e) {
        e.preventDefault();
    }    

    render() {

        return (
            <MessagePage 
                header="Invalid Resource" 
                content={"We do not have enough data to process your request at this time, please check if you are using the correct parameters."}
                />
        );
    }
}

export { NotFound };